<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <b-button variant="info" @click="editMeasurementUnit()">Создать клинику</b-button>
        <br /><br />
        <div v-if="error" class="alert alert-danger">
          {{error}}
        </div>
        <b-table striped hover :items="items" :fields="fields" ref="measurementUnitTable">
          <!-- A custom formatted column -->
<!--          <template v-slot:cell(value)="data">-->
<!--            <b>{{ data.item.value }}</b>-->
<!--          </template>-->
<!--          <template v-slot:cell(text)="data">-->
<!--            <span v-html="data.item.text"></span>-->
<!--          </template>-->
          <template v-slot:cell(clinicComment)="data">
            <span v-html="data.item.clinicComment"></span>
          </template>


          <template v-slot:cell(buttons)="data">
            <h3>
              <nobr>
                <b-link @click="editMeasurementUnit(data.item)" ><b-icon variant="info" icon="pencil" size="lg" ></b-icon></b-link>
                &nbsp;
                <b-link @click="deleteMeasurementUnit(data.item.clinicId, data.item.clinicName)"><b-icon variant="info" icon="x-circle" size="lg" ></b-icon></b-link>
              </nobr>
            </h3>
          </template>
        </b-table>
        <b-modal id="clinicModal" hide-footer size="lg" title="Клиника">
          <ClinicEdit @save-success="afterSave" v-bind:error="error" v-bind:current-measurement-unit="currentMeasurementUnit"></ClinicEdit>
        </b-modal>
        <Loader v-bind:showLoader="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import ClinicEdit from '@/components/admin/doctors/ClinicEdit'
import Loader from '@/components/Loader'
import {host} from '@/constants'
export default {

  name: "ClinicList",
  components: {
    Loader, ClinicEdit
  },
  data() {
    return {
      fields: [
        {
          key: 'clinicId',
          label: 'Id',
          sortable: true
        },
        {
          key: 'clinicName',
          label: 'Название клиники',
          sortable: true
        },
        {
          key: 'clinicComment',
          label: 'Комментарий',
          sortable: true
        },
        {
          key: 'buttons',
          label: ' ',
          sortable: false
        }
      ],
      items: [],
      loading: true,
      error: null,
      currentMeasurementUnit: null
    }
  },
  methods: {
    deleteMeasurementUnit(paramId, paramName) {
      this.error = null;
      if (confirm('Вы уверены, что хотите удалить клинику "' + paramName + '"')) {
        this.loading = true;
        fetch(host + '/rest/clinics/' + paramId, {
          method: 'DELETE',
          credentials: 'include',
          headers: {'Content-Type': 'application/json; charset=utf-8'}
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode == 0) {
                this.items = this.items.filter(item => item.value !== paramId)

              } else {

                this.error = json.message;
              }
              this.loading = false
              this.fetchData()

            }).catch(error => {
          this.error = error
          console.log(JSON.stringify(error))
          this.loading = false
        })
      }
    },
    editMeasurementUnit(item = null) {
      this.currentMeasurementUnit = item
      this.$bvModal.show('clinicModal')
    },
    fetchData() {
      fetch(host + '/rest/clinics', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.items = json.clinics
            this.loading = false
            console.log(json)
          })
    },
    afterSave() {
      this.$bvModal.hide('clinicModal')
      this.loading = true
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  }
}
</script>

<style scoped>
h3 {
  text-align: -webkit-right!important;
  text-align: -moz-right!important;
  text-align: right!important;
}

</style>